@import '../../styles/variables.scss';

.navigation {
  background-color: #fff;
  position: fixed;
  box-shadow: 0 0 5px grey;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9990;

  .mobile-nav-trigger {
    display: none;
    font-size: 28px;
    color: $blue;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    gap: 3px;
    font-weight: 900;

    img {
      height: 60px;
    }
  }

  & .wa-icon i {
    color: green;
    font-size: 44px;
  }

  a.book-now {
    background: $blue;
    border: none;
    padding: 5px 10px;
    font-weight: 900;
    border-radius: 5px;
    font-size: 16px;
    color: #fff;
    transition: all .3s ease-in-out;

    &:hover {
      background: #37889c;
      color: #fff;
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 15px;

    li {
      text-decoration: none;
      color: $white;
      cursor: pointer;
      transition: 0.3s all ease-in-out;
      font-weight: bold;

      &.active {
        color: $blue;
      }

      &.dropdown-toggle {
        position: relative;
        display: flex;
        gap: 8px;

        .dropdown-toggle-activator {
          display: inline-block;
          padding: 3px 0;
          color: $dark-gray;
        }

        i {
          color: $dark-gray;
        }

        .dropdown-items-container {
          position: absolute;
          top: 20px;
          left: 0;
          min-width: max-content;
          opacity: 0;
          overflow: visible;
          display: none;
          transition: all .3s ease-in-out;

          &.active {
            display: block;
          }

          &.visible {
            opacity: 100%;
          }
        }

        .triangle-container {
          position: relative;
          height: 18px;
        }

        .triangle-up {
          position: absolute;
          bottom: 0;
          left: 30px;
          width: 0;
          height: 0;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-bottom: 15px solid $submenu-gray;
        }

        .dropdown-items {
          padding: 20px 15px;
          display: flex;
          flex-direction: column;
          background: $submenu-gray;
          border-radius: 15px;

          li {
            width: 100%;
          }

          a {
            text-align: left;
            color: white;

            &:hover,
            &.active {
              color: $blue;
            }
          }
        }
      }

      a {
        text-decoration: none;
        color: $dark-gray;
        cursor: pointer;
        transition: 0.3s all ease-in-out;
        font-weight: bold;

        &:hover,
        &.active {
          color: $blue;
        }
      }
    }
  }

  &__left {
    a {
      color: $white;
      text-decoration: none;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
}

.mobile-nav {
  position: fixed;
  top: 70px;
  right: -100%;
  bottom: 0;
  background: $dark-gray;
  width: 200px;
  padding: 20px;
  transition: all .3s ease-in-out;

  &.active {
    right: 0;
  }

  &__list {
    a {
      color: #fff;
      text-decoration: none;
      margin-bottom: 20px;
      display: block;
      font-size: 18px;
      text-align: center;

      &.active {
        color: $blue;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    list-style: none;

    .mobile-nav-whatsapp {
      background: green;

      i {
        color: #fff;
      }
    }

    .mobile-nav-email {
      background: #37889c;

      i {
        color: #fff;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #fff;
      color: $dark-gray;
      font-size: 24px;
      text-decoration: none;
    }
  }

  .book-now {
    background: $blue;
    padding: 5px 10px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    transition: all .3s ease-in-out;

    &:hover {
      background: #37889c;
    }
  }
}

@media screen and (max-width: 1024px) {
  .navigation {
    padding: 0 15px;

    ul {
      display: none;
    }

    .mobile-nav-trigger {
      display: inline;
      font-size: 34px;
    }

    &__right {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      gap: 15px;
      flex-grow: 1;
      padding-right: 25px;
    }

    & .wa-icon i {
      font-size: 40px;
    }
  }
}