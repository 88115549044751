@import '../../styles/variables.scss';

@import '@splidejs/splide/css';

@import './hero.scss';
@import './services.scss';

.our-services {
  margin-bottom: 50px;

  h2 {
    margin-bottom: 30px;
    text-align: center;
    margin-bottom: 30px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 20px;
    padding-bottom: 10px;

    a {
      text-decoration: none;
      transition: all 0.3s ease-in-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  &__item {
    border-radius: 15px;
    box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
    -webkit-box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
    -moz-box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);

    img {
      width: 100%;
      height: 150px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  &__item-content {
    padding: 10px 15px;

    h3 {
      text-align: center;
      font-weight: bold;
      color: #000;
      font-size: 18px;
    }
  }
}

.reviews {
  margin-bottom: 50px;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  &__item {
    padding: 0 75px 5px 75px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__guest-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 15px;
  }

  &__guest-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  &__guest-name {
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  &__review-content {
    padding: 20px 20px 35px 20px;
    height: 100%;
    background: #fff;
    box-shadow: 3px 3px 5px 0px rgba(29, 35, 41, 0.4);
    -webkit-box-shadow: 3px 3px 5px 0px rgba(29, 35, 41, 0.4);
    -moz-box-shadow: 3px 3px 5px 0px rgba(29, 35, 41, 0.4);
    border-radius: 15px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.2em;
      text-align: center;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.map-section {
  padding-bottom: 6px;
  margin-bottom: 50px;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.map-container {
  width: 1000px;
  height: 400px;

  iframe {
    border-radius: 20px;
    box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
    -webkit-box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
    -moz-box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
  }
}

.get-in-touch {
  margin-bottom: 50px;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    gap: 50px;
  }

  &__item {
    font-size: 60px;
    color: #fff;
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
    text-decoration: none;

    &:hover {
      transform: scale(1.05);
    }
  }

  .email-contact {
    background: #37889c;
  }

  .whatsapp-contact {
    background: green;
  }

  .phone-contact {
    background: #4CB1CA;
  }
}

@media screen and (max-width: 768px) {
  .our-services {
    &__list {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__item-content h3 {
      min-height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__item {
      img {
        height: 100px;
      }
    }
  }

  .map-container {
    width: 100%;
  }

  .reviews {
    &__item {
      padding: 0;
    }
  }

  .services {
    margin-bottom: 30px;
  }

  .get-in-touch {
    &__item {
      height: 75px;
      width: 75px;
      font-size: 40px;
    }
  }
}