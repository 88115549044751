@import './variables.scss';

body {
  font-family: Poppins, Helvetica, sans-serif;
  background: $light-gray;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

.faq-container {
  margin-bottom: 30px;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.intro-section {
  margin-bottom: 30px;
}

.intro-text {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.4em;
  text-align: center;
}

input {
  padding: 5px 15px;
  border-radius: 10px;
  border: 1px solid $dark-gray;
  height: 44px;
  line-height: 44px;
}

input[type="date"] {
  font-family: Poppins, Helvetica, sans-serif;
  width: 100%;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  text-align: left;
}

input[type="submit"] {
  font-family: Poppins, Helvetica, sans-serif;
  height: auto;
  line-height: auto;
  cursor: pointer;
  border: none;
}

select {
  position: relative;
  padding: 0 15px;
  border-radius: 10px;
  border: 1px solid $dark-gray;
  height: 44px;
  line-height: 44px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  color: #000;

  &::after {
    position: absolute;
    content: "⌄";
    top: 50%;
    right: 15px;
    width: 5px;
    height: 5px;
    transform: translateY(50%);
  }
}

input[type="submit"] {
  background: $dark-gray;
  width: fit-content;
  color: white;
  transition: all .3s ease-in-out;
  font-weight: bold;

  &:hover {
    background: $blue;
  }
}

.ac {
  padding: 10px 15px;
  border-radius: 25px;
  margin-bottom: 20px;
  box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
  -webkit-box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
  -moz-box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);

  .ac-trigger:focus {
    color: #000 !important;
  }
}

.iti {
  width: 100%;

  input {
    width: 100%;
  }
}

h1 {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}

h2 {
  font-size: 25px;
  font-weight: 700;
}

h3 {
  color: $white;
  font-size: 25px;
}

p {
  font-size: 20px;
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 760px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
  }

  h1 {
    font-size: 30px;
  }

  .intro-text {
    font-size: 22px;
  }
}