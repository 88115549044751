.services {
  background-color: $light-gray;
  margin-bottom: 30px;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  &__grid-new {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 30px;

    .service-list-item {
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 30px;
      padding: 15px;
      margin-bottom: 30px;
      box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
      -webkit-box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);
      -moz-box-shadow: 3px 5px 5px 0px rgba(29, 35, 41, 0.4);

      &.left-aligned {
        grid-template-columns: 60% 40%;
        gap: 50px;
      }

      &__description {
        margin-bottom: 30px;
        font-size: 18px;
        line-height: 1.3em;
      }

      &__pricing {
        padding-left: 15px;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;

        b {
          padding: 5px;
          background-color: $blue;
          color: #fff;
          font-weight: bold;
          border-radius: 10px;
          font-size: 16px;
        }
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 20px;

        a {
          padding: 10px 20px;
          background: $dark-gray;
          color: #fff;
          width: fit-content;
          border: none;
          border-radius: 15px;
          font-size: 18px;
          font-weight: bold;
          text-decoration: none;
          transition: all .2s ease-in-out;
          margin-bottom: 10px;

          &:hover {
            background-color: $blue;
          }
        }
      }

      img {
        width: 300px;
        border-radius: 20px;
      }
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }

  &__title {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__list-item {
    background-color: $white;
    border-radius: 15px;
    cursor: pointer;
    text-decoration: none;

    img {
      width: 100%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    h3 {
      color: $dark-gray;
      font-weight: bold;
      font-size: 20px;
    }

    p {
      font-size: 15px;
      color: $dark-gray;
    }
  }

  &__list-item-price {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: $dark-gray;
    padding: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-top: 10px;

    span:first-child {
      font-size: 15px;
      color: $white;
    }

    span:last-child {
      font-size: 15px;
      font-weight: bold;
      padding: 5px 10px;
      background-color: $blue;
      color: $white;
      border-radius: 15px;
    }
  }

  &__outro {
    line-height: 1.5em;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .services {
    &__grid-new {
      .service-list-item {
        flex-direction: column-reverse;

        &__content {
          padding: 0;
        }

        &__description {
          margin-bottom: 20px;
        }

        a {
          line-height: 1.3em;
        }

        img {
          width: 100%;
          border-radius: 20px;
        }

        &.left-aligned {
          gap: 20px;
        }
      }
    }
  }
}