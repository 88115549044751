.hero {
  background-image: url("../../assets/location.webp");
  background-position: center center;
  background-size: cover;
  color: $white;
  height: 300px;
  position: relative;
  margin-top: 80px;
  margin-bottom: 30px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
      text-align: center;
      font-weight: bold;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    &>p {
      margin-bottom: 0;
    }
  }

  &__buttons {
    display: flex;
    padding: 15px 20px;
    transition: 0.3s all ease-in-out;

    a {
      border-radius: 15px;
    }

    &:hover {
      a {
        background: #37889c;
        color: $white;
      }
    }

    a {
      text-decoration: none;
      text-align: center;
      background: $blue;
      color: $white;
      font-weight: bold;
      font-size: 20px;
      padding: 10px 15px;
      cursor: pointer;
      width: 200px;
      transition: 0.3s all ease-in-out;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero {
    height: 300px;
  }
}

@media screen and (max-width: 660px) {
  .hero {
    .container {
      gap: 25px;
    }

    &__buttons {
      gap: 10px;

      button {
        width: 100px;
        font-size: 16px;
        padding: 10px;
      }
    }
  }
}