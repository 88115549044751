.mobile-nav {
  position: fixed;
  top: 75px;
  right: -100%;
  bottom: 0;
  background: #fff;
  width: 200px;
  padding: 20px;
  transition: all .3s ease-in-out;
  box-shadow: 0 5px grey;

  &.active {
    right: 0;
  }

  &__list {
    a {
      color: $dark-gray;
      text-decoration: none;
      margin-bottom: 20px;
      display: block;
      font-size: 20px;
      text-align: center;
      font-weight: bold;

      &.active {
        color: $blue;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    list-style: none;

    .mobile-nav-whatsapp {
      background: green;

      i {
        color: #fff;
      }
    }

    .mobile-nav-email {
      background: #37889c;

      i {
        color: #fff;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background: #fff;
      color: $dark-gray;
      font-size: 24px;
      text-decoration: none;
    }
  }

  .book-now {
    background: $blue;
    padding: 5px 10px;
    color: #fff;
    font-weight: bold;
    border-radius: 10px;
    transition: all .3s ease-in-out;

    &:hover {
      background: #37889c;
    }
  }
}

.mobile-dropdown-item {
  span {
    color: $dark-gray;
    text-decoration: none;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    &.active {
      color: $blue;
    }
  }

  .mobile-dropdown-menu-list {
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;

    ul {
      li {
        a {
          font-size: 14px;
          margin-bottom: 10px;
          line-height: 1.3em;
        }

        &:last-of-type {
          margin-bottom: 20px;
        }
      }
    }

    &.active {
      max-height: 500px;
      transition: max-height 0.3s ease-in-out;
    }
  }
}