footer {
  background: #fff;
  padding: 30px 0;
  box-shadow: 0px -1px 1px 0px rgba(0, 0, 0, 0.3);

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .footer-top {
    padding: 30px 0;
    width: 100%;
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul {
      display: flex;
      gap: 20px;

      &.social-media-list {
        li {
          font-size: 2em;
        }
      }

      a {
        flex-grow: 1;
        font-family: Poppins, Helvetica, sans-serif;
        font-weight: bold;
        text-decoration: none;
        color: $dark-gray;

        &:active,
        &:hover {
          color: $blue;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    width: 100%;

    &__left {
      display: flex;
      gap: 30px;

      p {
        font-size: 14px;
      }
    }

    &__left-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;

      ul {
        display: flex;
        gap: 10px;

        li a {
          font-size: 14px;
          text-decoration: none;
          font-weight: bold;
          color: $dark-gray;

          &:active,
          &:hover {
            color: $blue;
          }
        }
      }
    }
  }

  .logo {
    width: 100px;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 20px;

    a {
      padding: 10px 15px;
      color: #fff;
      display: flex;
      gap: 10px;
      align-items: center;
      text-decoration: none;
      font-weight: bold;
      border-radius: 10px;

      i {
        color: #fff;
      }
    }

    .book-now {
      background: $blue;
    }

    .whatsapp {
      background: green;
    }

    .email {
      background: #37889c;
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    .container {
      flex-direction: column;
    }

    .logo {
      margin-bottom: 20px;
      text-align: center;
    }

    .buttons {
      flex-direction: column;
      gap: 15px;
    }
  }
}

@media screen and (max-width: 1024px) {
  footer {
    padding-top: 0;

    .footer-top {
      flex-direction: column;
      gap: 30px;
    }

    .footer-bottom {
      width: 100%;
      justify-content: center;

      &__left {
        flex-direction: column;
        align-items: center;
        gap: 10px;
      }

      &__left-details {
        align-items: center;

        ul {
          flex-direction: column;

          li {
            text-align: center;
          }
        }
      }
    }
  }
}